<template>
  <v-container class="fill-heigt py-10" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="8">
        <details-card :guest="true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADD_VIEWS_VACANCY } from '../graphql/Mutation.gql'
import DetailsCard from '../modules/vacancy/components/DetailsCard.vue'
export default {
  components: { DetailsCard },
  name: 'Vacancy',
  created () {
    this.vacancyId = this.$route.params.id
    this.addViews()
  },
  methods: {
    addViews () {
      this.$apollo.mutate({
        mutation: ADD_VIEWS_VACANCY,
        variables: {
          id: this.$route.params.id
        }
      })
    },
  }
}
</script>