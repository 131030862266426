import gql from 'graphql-tag'

export const ADD_VIEWS_VACANCY = gql `
  mutation AddViewsVacancy($id: ID!) {
    addViewsVacancy(id: $id)
  }
`

export const SEND_STATUS = gql `
  mutation sendStatus($user_id: ID, $description: String, $locale: String) {
    sendStatus(user_id: $user_id, description: $description, locale: $locale){
      description
      locale
      candidate
    }
  }
`

export const CHANGE_ENTITY_MODULE_MUTATION = gql `
  mutation ChangeEntityModule($entityModuleInput: EntityModuleInput!) {
    changeEntityModule(entityModuleInput: $entityModuleInput) {
      id
      amountPaid
      date
      expired
      module {
        id
        minimumProfiles
        name
        permissions {
          id
          name
        }
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
        minimumDuration
      }
      totalProfiles
      totalUsers
      type
      value
    }
  }
`;

export const UPDATE_ENTITY_MODULE_MUTATION = gql `
  mutation UpdateEntityModule($id: ID!, $entityModuleInput: EntityModuleInput!) {
    updateEntityModule(id: $id, entityModuleInput: $entityModuleInput) {
      id
      amountPaid
      date
      expired
      module {
        id
        minimumProfiles
        name
        permissions {
          id
          name
        }
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
        minimumDuration
      }
      totalProfiles
      totalUsers
      type
      value
    }
  }
`

export const UPGRADE_CURRENT_MODULE_MUTATION = gql `
  mutation upgradeCurrentModule($id: ID!, $entityModuleInput: EntityModuleInput!) {
    upgradeCurrentModule(id: $id, entityModuleInput: $entityModuleInput) {
      id
      amountPaid
      date
      expired
      module {
        id
        minimumProfiles
        name
        permissions {
          id
          name
        }
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
        minimumDuration
      }
      totalProfiles
      totalUsers
      type
      value
    }
  }
`